import React from "react";

export const SocialIcons = (props: any) => {
  const openUrl = (url: string) => {
    return window.open(url, "_blank");
  };

  return (
    <div className="fixed bottom-[50px] mt-[-60px] right-[50px] space-y-[15px] flex items-center justify-center flex-col">
      {/* Next & Prev button */}
      <img
        src="/assets/whatsapp.png"
        alt="whatsapp"
        className={`${$btnStyle} `}
        onClick={() =>
          openUrl("https://api.whatsapp.com/send/?phone=61450680626")
        }
      />
    </div>
  );
};

const $btnStyle = `cursor-pointer w-[50px] drop-shadow rounded-full drop-shadow-md`;
