import React from "react";

export const ArtworkOne = (props: any) => {
  return (
    <div className="w-full flex flex-col-reverse md:flex-row bg-[#FBFBFB] min-h-[400px] items-center justify-center p-[50px]">
      {/* Artwork */}
      <div className="relative mt-[20px]">
        <img
          src="/assets/5.avif"
          alt="Ceramic Vase Turquois Pollen by Adam Knoche Glaze"
          className="w-[600px] h-[300px] object-cover"
        />
        <h2 className="absolute top-[-10px] left-[-50px] text-slate-300 text-[36px] font-[400]">
          D.N.A.
        </h2>
      </div>

      {/* Intro */}
      <div className="pl-[0px] md:pl-[50px] flex flex-row-reverse space-x-[20px]">
        <h2 className="text-slate-500 rotated-invert pl-[0px] md:pl-[50px]">
          Artwork
        </h2>
      </div>
    </div>
  );
};
