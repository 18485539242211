import React from "react";

export const Featured = (props: any) => {
  return (
    <div className="w-full flex flex-col md:flex-row bg-[#fff] min-h-[400px] p-[50px] items-center justify-center">
      {/* Title */}
      <div className="pr-[0px] md:pr-[50px] flex flex-row-reverse space-x-[20px]">
        <h2 className="text-black rotated pr-[0px] md:pr-[50px] text-slate-500">
          Featured Ceramics
        </h2>
      </div>
      {/* Artwork */}
      <div className={$artworkWrapperStyle}>
        <img
          src="/assets/2.webp"
          alt="Large-Scale, Glazed Ceramic Llama, Italy, circa 1970"
          className={$artworkStyles}
        />
        <p className={$artworkTxtStyle}>
          Large-Scale, Glazed Ceramic Llama, Italy, circa 1970
        </p>
      </div>
      <div className={$artworkWrapperStyle}>
        <img
          src="/assets/3.webp"
          alt="Modern Ceramic Sculpture by Jerilyn Virden"
          className={$artworkStyles}
        />
        <p className={$artworkTxtStyle}>
          Modern Ceramic Sculpture by Jerilyn Virden
        </p>
      </div>
      <div className={$artworkWrapperStyle}>
        <img
          src="/assets/4.webp"
          alt="Vase, Ceramics by Hans Hedberg, Biot, France"
          className={$artworkStyles}
        />
        <p className={$artworkTxtStyle}>
          Vase, Ceramics by Hans Hedberg, Biot, France
        </p>
      </div>
    </div>
  );
};

const $artworkStyles =
  "h-[250px] object-cover aspect-square overflow-hidden rounded-full";

const $artworkWrapperStyle =
  "mt-[50px] md:mt-[0px] ml-[0px] md:ml-[50px] flex flex-col items-center justify-start max-w-[250px]";

const $artworkTxtStyle = "pt-[20px] text-slate-400 text-center text-[10px]";
