import React from "react";

export const Banner = (props: any) => {
  return (
    <div className="w-full flex flex-col-reverse md:flex-row bg-[#FBFBFB] min-h-[400px] items-center justify-center">
      {/* Artwork */}
      <img
        src="/assets/1.png"
        alt="Ceramic Vase Turquois Pollen by Adam Knoche Glaze"
        className="h-[500px] object-cover aspect-square"
      />

      {/* Intro */}
      <div className="pl-[50px] flex flex-row md:flex-row-reverse space-x-[20px] py-[50px] md:py-[0px]">
        <h2 className="text-black rotated-invert pl-[0px] md:pl-[50px]">
          N3 Ceramics
        </h2>
        <p className="text-black rotated-invert">
          Ceramic Vase Turquois Pollen
        </p>
        <p className="text-black rotated-invert">by Adam Knoche Glaze</p>
        <p className="text-black rotated-invert">
          Cone Six Oxidation, Porcelain
        </p>
      </div>
    </div>
  );
};
