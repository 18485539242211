import React from "react";

export const Showcases = (props: any) => {
  return (
    <div className="w-full flex flex-col md:flex-row bg-[#fff] min-h-[400px] p-[50px] items-start justify-center">
      {/* Title */}
      <div className="pr-[50px] flex flex-col md:flex-row space-x-[0px] md:space-x-[20px]">
        <h2 className="text-black rotated pr-[0px] md:pr-[50px] text-slate-500">
          Showcases
        </h2>
        <p className="text-slate-400 rotated pt-[20px] md:pt-[0px]">
          Contact us to buy the marvelous Ceramics
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-[50px] mt-[50px] md:mt-[0px] mx-auto md:mx-[0px]">
        {/* Artwork */}
        <div className={$artworkWrapperStyle}>
          <img
            src="/assets/6.png"
            alt="Adam Knoche Ceramic Vessel “Basalt Scoria” Clay, Porcelain, Glaze, Cone Six Oxid"
            className={$artworkStyles}
          />
          <p className={$artworkTxtStyle}>
            Adam Knoche Ceramic Vessel “Basalt Scoria” Clay, Porcelain, Glaze,
            Cone Six Oxid
          </p>
        </div>
        <div className={$artworkWrapperStyle}>
          <img
            src="/assets/7.webp"
            alt={`Adam Knoche Contemporary Red Ceramic Vase "Cadmium Fissure" Clay, Glaze 2022`}
            className={$artworkStyles}
          />
          <p className={$artworkTxtStyle}>
            {`Adam Knoche Contemporary Red Ceramic Vase "Cadmium Fissure" Clay, Glaze 2022`}{" "}
          </p>
        </div>
        <div className={$artworkWrapperStyle}>
          <img
            src="/assets/8.webp"
            alt="Adam Knoche Contemporary Vase Gum Sap Porcelain, Pink Orange Contemporary Clay"
            className={$artworkStyles}
          />
          <p className={$artworkTxtStyle}>
            Adam Knoche Contemporary Vase Gum Sap Porcelain, Pink Orange
            Contemporary Clay
          </p>
        </div>
        <div className={$artworkWrapperStyle}>
          <img
            src="/assets/9.webp"
            alt="Ceramic Vase Bleached Amarillo Adam Knoche Yellow Contemporary Glazed Vessel"
            className={$artworkStyles}
          />
          <p className={$artworkTxtStyle}>
            Ceramic Vase Bleached Amarillo Adam Knoche Yellow Contemporary
            Glazed Vessel
          </p>
        </div>
        <div className={$artworkWrapperStyle}>
          <img
            src="/assets/10.webp"
            alt="Adam Knoche Bleached Rosa Contemporary Ceramic Vase Glaze Cone Six Oxidation"
            className={$artworkStyles}
          />
          <p className={$artworkTxtStyle}>
            Adam Knoche Bleached Rosa Contemporary Ceramic Vase Glaze Cone Six
            Oxidation
          </p>
        </div>
        <div className={$artworkWrapperStyle}>
          <img
            src="/assets/1.png"
            alt="Ceramic Vase Turquois Pollen by Adam Knoche Glaze"
            className={$artworkStyles}
          />
          <p className={$artworkTxtStyle}>
            Ceramic Vase Turquois Pollen by Adam Knoche Glaze
          </p>
        </div>
      </div>
    </div>
  );
};

const $artworkStyles =
  "h-[250px] object-cover aspect-square overflow-hidden rounded-full";

const $artworkWrapperStyle =
  "ml-[0px] md:ml-[50px] flex flex-col items-center justify-start max-w-[250px]";

const $artworkTxtStyle = "pt-[20px] text-slate-400 text-center text-[10px]";
