import React from "react";
import { Container } from "../../components";
import { Banner } from "./banner";
import { SocialIcons } from "src/components/social-icons";
import { Featured } from "./featured";
import { ArtworkOne } from "./artwork-one";
import { Showcases } from "./showcases";

export const Home = (props: any) => {
  return (
    <Container>
      <Banner />
      <Featured />
      <ArtworkOne />
      <Showcases />

      {/* Floating Social Media Icons */}
      <SocialIcons />
    </Container>
  );
};
